import { captureException } from './sentry'

// MathJax needs to be re-rendered for elements that were display: none
// see http://www.peterkrautzberger.org/0165/ for more information
export function rerenderMathJax($el) {
  if (window.MathJax) {
    window.MathJax.Hub.processSectionDelay = 0
    window.MathJax.Hub.processUpdateTime = 0
    window.MathJax.Hub.processUpdateDelay = 0

    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, $el, ckeditorScrub])
  } else {
    captureException('MathJax failed to load')
  }
}

function ckeditorScrub() {
  document.querySelectorAll('lz-content ul > li script').forEach((node) => (node.textContent = ''))
}
