import omit from 'lodash/omit'
import $script from 'scriptjs'
import { deepKeyTransform } from '../utils'
import { captureException } from './sentry'

function initialize(userTraits, customerTraits) {
  if (!userTraits.userId) return

  window.pendo.initialize({
    visitor: userTraitsToPendo(userTraits),
    account: customerTraitsToPendo(customerTraits),
  })
}

function userTraitsToPendo(traits) {
  return {
    id: traits.userId,
    email: traits['$email'],
    full_name: traits['$name'],
    role: traits['Application role'],
    ...formatProperties(omit(traits, ['userId', '$email', '$name', 'Application role'])),
  }
}

function customerTraitsToPendo(traits) {
  return {
    id: traits.customer,
    name: traits['customer name'],
    ...formatProperties(omit(traits, ['customer', 'customer name'])),
  }
}

function formatProperties(properties) {
  // Must only use letters, numbers, or underscores.
  // Can't begin and end with double underscores __*__.
  // Must be 32 characters or fewer.
  // Can't begin with a number.
  return deepKeyTransform(properties, (key) =>
    key
      .toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll(/[^a-z0-9_]/gi, ''),
  )
}

// This is from pendo with the code to load the script removed. It should create window.pendo so any calls to it before
// it has been loaded won't fail.
function defaultPendoScript() {
  ;(function (p, e, n, d, o) {
    let w, x
    o = p[d] = p[d] || {}
    o._q = o._q || []
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
          }
      })(v[w])
  })(window, document, 'script', 'pendo')
}

function load(apiKey, userTraits, customerTraits) {
  defaultPendoScript()

  return new Promise((resolve) => {
    $script(`https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`, function () {
      try {
        initialize(userTraits, customerTraits)
      } catch (e) {
        captureException(e)
      }
      resolve()
    })
  })
}

function track(eventName, properties) {
  // FIXME: The event properties are too large for Pendo. Need to figure out how it should be handled. Disabling it in the mean time.
  // window.pendo.track(eventName, formatProperties(properties))
}

export default {
  load,
  track,
}
