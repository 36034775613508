<template>
  <LzButton
    :class="buttonClasses"
    :disabled="disabled"
    :aria-pressed="String(isSelected)"
    tabindex="0"
    @click="toggle(value)"
  >
    <slot />
  </LzButton>
</template>

<script>
import { computed, inject } from 'vue'
import LzButton from './LzButton'

const BASE_CLASSES = ['text-xs font-bold cursor-pointer focus:border-primary-500']

export default {
  name: 'CheckButtonOption',
  components: { LzButton },
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const name = inject('name')
    const toggle = inject('toggle')
    const selections = inject('selections')
    const isSelected = computed(() => selections.value.includes(props.value))
    const buttonClasses = computed(() => [
      ...BASE_CLASSES,
      {
        'bg-white': !isSelected.value,
        'bg-primary-700': isSelected.value,
        'border-primary-700': isSelected.value,
        'text-white': isSelected.value,
      },
    ])

    return { name, toggle, isSelected, buttonClasses }
  },
}
</script>
