import assign from 'lodash/assign'
import compact from 'lodash/compact'
import get from 'lodash/get'
import each from 'lodash/each'
import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import set from 'lodash/set'

import debug from 'debug'
const log = debug('app:clients/learnosity')

import { apiVersion, isEnabled } from './learnosity/config'
import {
  loadItemsAppScript,
  resetItemsAppScript,
  loadAuthorAppScript,
  resetAuthorAppScript,
} from './learnosity/loaders'
import { captureException } from './sentry'

log('api version', apiVersion)

function getOrSet(object, path, callback) {
  let value = get(object, path)
  value = value ? value : callback()
  set(object, path, value)
  return value
}

const itemsAppRegistry = {}
const itemsAppStub = {
  fetchItems() {
    return {}
  },
  questions() {
    return {}
  },
  on() {},
}

function configRegistryKey(config) {
  return config?.request?.sessionId
}

export function fetchItemsApp(config) {
  if (!isEnabled()) {
    log('not connected to the Learnosity Items API')
    return Promise.resolve(itemsAppStub)
  }

  const key = configRegistryKey(config)

  if (!key) {
    throw new Error('NGItems.initialize requires a sessionId')
  }

  return getOrSet(itemsAppRegistry, key, () => newItemsApp(config, key))
}

function newItemsApp(config, sessionId) {
  return new Promise((resolve, reject) => {
    log('NGItems.initialize begin', sessionId)

    let app

    const customId = config.customId
    delete config.customId

    const callbacks = {
      onReady() {
        log('NGItems.initialize resolve', sessionId, app)
        resolve(app)
      },
      onError(err) {
        captureLearnosityError(err, { during: 'NGItems.initialize reject', sessionId })
        reject(err)
      },
    }

    loadItemsAppScript().then(() => {
      if (customId) {
        app = window.NGItems.initialize(config, callbacks, customId)
      } else {
        app = window.NGItems.initialize(config, callbacks)
      }
    })
  })
}

export function reset(options = {}) {
  const includeScripts = options.includeScripts
  const config = options.config

  if (includeScripts) {
    resetItemsAppScript()
    resetAuthorAppScript()
  }

  if (config) {
    const key = configRegistryKey(config)
    delete itemsAppRegistry[key]
  } else {
    Object.keys(itemsAppRegistry).forEach((key) => delete itemsAppRegistry[key])
  }
}

// use LearnosityAuthorClient instead of using this directly
export function newAuthorApp(config) {
  return new Promise((resolve, reject) => {
    loadAuthorAppScript().then(() => {
      const app = window.NGAuthor.initialize(config, {
        onReady: () => resolve(app),
        onError: (err) => reject(err),
      })
    })
  })
}

export function captureLearnosityError(errorOrDetails = {}, context = {}) {
  log(context, errorOrDetails)
  const extra = assign({}, context)

  const globalErrors = compact(
    flatMap(globalNamespaces, (global) => {
      return map(get(window, `${global}.errors`), (error, index) => {
        return assign({ global, index }, error)
      })
    }),
  )

  each(globalErrors, (error) => {
    extra[`${error.global}.errors[${error.index}]`] = compact([error.code, error.msg]).join(': ')
  })

  if (errorOrDetails instanceof Error) {
    return captureException(errorOrDetails, { extra })
  }

  const {
    code = get(globalErrors[0], 'code'),
    msg = get(globalErrors[0], 'msg') || 'Unspecified Learnosity error',
    ...rest
  } = errorOrDetails

  return captureException(new Error(compact([code, msg]).join(': ')), { extra: assign(extra, rest) })
}

export const globalNamespaces = ['NGItems', 'NGAuthor', 'LearnosityApp']
